import { HomeContainer } from "./HomeContainer";
import { Route, Routes } from "react-router";
import { AssessmentSessionViewRoutes } from "@idsk/assessment-session";
export const RootContainer = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<HomeContainer />} />
        <Route index element={<HomeContainer />} />
        <Route
          path="/clients/:clientId/assessment-sessions/:id/view/*"
          element={<AssessmentSessionViewRoutes />}
        />
      </Routes>
    </>
  );
};
