import { Navigate, Route, Routes } from "react-router-dom";
import { QuestionBankRoutes } from "@idsk/questionbank";
import { AssessmentRoutes } from "@idsk/assessment";
import { AssessmentAdministrationRoutes } from "@idsk/assessment-administration-app";
import { UserGuideRoutes } from "@idsk/userguide-app";
import { CampusAdministrationRoutes } from "@idsk/campus-administration-app";
import { StudentDataRoutes } from "@idsk/candidate-student";
import { CandidateGroupRoutes } from "@idsk/candidate-group";
import { LmsRouter } from "@idsk/lms-web-app";
import { AdminRouter } from "@idsk/admin-web-app";
import { RolesAndPermissionRoutes } from "@idsk/roles-and-permissions-app";
import { UserGroupRoutes } from "@idsk/user-group-app";
import { UserManagementRoutes } from "@idsk/user-management-app";
import {
  UserRoleActions,
  permissionGroupAccessConstants,
  permissionAccessConstants,
} from "@idsk/components-props";
import { UserAccessWrapper } from "@idsk/components-ui";
import { CandidateReportRoutes } from "@idsk/candidate-report";

export const RootRouter = () => {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={"clients/self/questions"} replace={true} />}
      />
      <Route
        path="*"
        element={<Navigate to={"/clients/self/questions"} replace={true} />}
      />
      <Route
        path="/clients/:clientId/questions/*"
        element={<QuestionBankRoutes />}
      />
      <Route path="/users/*" element={<StudentDataRoutes />} />
      <Route
        path="/clients/:clientId/assessments/*"
        element={<AssessmentRoutes />}
      />
      <Route path="/groups/*" element={<CandidateGroupRoutes />} />

      <Route
        path="/clients/:clientId/assessmentadministrations/*"
        element={<AssessmentAdministrationRoutes />}
      />
      <Route path="/userguide/*" element={<UserGuideRoutes />} />
      <Route
        path="/clients/:clientId/students/*"
        element={<CampusAdministrationRoutes />}
      />
      <Route path="/lms/*" element={<LmsRouter />} />
      <Route path="/admin/*" element={<AdminRouter />}></Route>
      {/* <Route
        path="/manage/roles/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.rolesAndPermissions}
          >
            <RolesAndPermissionRoutes />
          </UserAccessWrapper>
        }
      /> */}
      <Route
        path="/manage/users/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.users}
          >
            <UserManagementRoutes />
          </UserAccessWrapper>
        }
      />
      <Route
        path="/manage/groups/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.groups}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
      <Route
        path="/manage/college/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.general}
            policyName={permissionAccessConstants.organizations}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
      <Route
        path="/manage/candidate-reports/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.reports}
            policyName={permissionAccessConstants.reports}
          >
            <CandidateReportRoutes />
          </UserAccessWrapper>
        }
      />
    </Routes>
  );
};
